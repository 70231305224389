import '../styles/styles.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useUserInput } from '../context/userInputContext';
import ChartText from './chartText';
//import pieLabelsLine from './pieLabelsLinePlugin.js' <- https://codepen.io/BillDou/pen/oNoGBXb

ChartJS.register(ArcElement, Tooltip, Legend);


// function to calculate tax and pay variables
const calculateTax = (
  salaryAmount,
  taxCode,
  location,
  partnerIncome,
  isBLind,
  showNI,
  hasStudentLoanPlan1,
  hasStudentLoanPlan2,
  hasStudentLoanPlan4,
  hasStudentLoanPostgrad,
) => {
  // init vars
  let
    personalAllowance,
    taxable,
    taxPaid,
    taxPercent,
    NI_Paid,
    NI_Percent,
    takeHome,
    studentLoansPayment,
    studentLoansPercent;

  personalAllowance = parseInt(taxCode) * 10;
  // if blind add £2,870 to personal allowance
  // https://www.gov.uk/blind-persons-allowance/what-youll-get
  if (isBLind) {
    personalAllowance += 2870
  }
  // Add personal allowance based on partner income
  // https://www.gov.uk/marriage-allowance
  personalAllowance += Math.min(Math.max(12570 - partnerIncome, 0), 1260);

  taxPaid = 0;
  NI_Paid = 0;
  studentLoansPayment = 0;

  /* personal allowance deductions
  -----------------------------------
    over £100,000: -£1 for every £2 over £100,000
  */
  personalAllowance -= Math.min(Math.max(salaryAmount - 100000, 0) / 2, personalAllowance);

  /* SCOTLAND income tax brackets
  ------------------------------------
    less than allowance:   0%
    allowance -> £14,733: 19%
    £14,733 -> £25,688:   20%
    £25,688 -> £43,662:   21%
    £43,662 -> £125,140:  42%
    more than £125,140:   47%
  */
  if (location === "SCT") {
    taxPaid += Math.max((Math.min(salaryAmount, 14732) - personalAllowance), 0) * 0.19;
    taxPaid += Math.max((Math.min(salaryAmount, 25688) - 14732), 0) * 0.2;
    taxPaid += Math.max((Math.min(salaryAmount, 43662) - 25688), 0) * 0.21;
    taxPaid += Math.max((Math.min(salaryAmount, 125140) - 43662), 0) * 0.42;
    taxPaid += Math.max(salaryAmount - 125140, 0) * 0.47;
  }
  /* GREAT BRITAIN income tax brackets
  ------------------------------------
    less than allowance:   0%
    allowance -> £50,270: 20%
    £50,270 -> £125,140:  40%
    more than £125,140:   45%
  */
  if (location === "GB") {
    taxPaid += Math.max((Math.min(salaryAmount, 50270) - personalAllowance), 0) * 0.2;
    taxPaid += Math.max((Math.min(salaryAmount, 125140) - 50270), 0) * 0.4;
    taxPaid += Math.max(salaryAmount - 125140, 0) * 0.45;
  }
  /*
    NI tax brackets
    ------------------------------------
    less than £12,570:      0%
    £12,570 -> £50,270:    12%
    more than £50,270:    2%
  */
  NI_Paid += Math.max((Math.min(salaryAmount, 50270) - 12570), 0) * 0.12;
  NI_Paid += Math.max(salaryAmount - 50270, 0) * 0.02;


  taxable = Math.max(salaryAmount - personalAllowance, 0);
  taxPaid = Math.round(taxPaid * 100) / 100;
  taxPercent = Math.round(taxPaid / salaryAmount * 100);

  if (showNI) {
    NI_Paid = Math.round(NI_Paid * 100) / 100;
  } else {
    NI_Paid = 0;
  }

  NI_Percent = Math.round(NI_Paid / salaryAmount * 100);



  // STUDENT LOANS
  // https://www.gov.uk/repaying-your-student-loan/what-you-pay
  // studentLoansPayment = lowest threshold payment + post grad payment

  if (hasStudentLoanPlan4) {
    studentLoansPayment = (Math.max(salaryAmount, 27660) - 27660) * 0.09;
  }

  if (hasStudentLoanPlan2) {
    studentLoansPayment = (Math.max(salaryAmount, 27295) - 27295) * 0.09;
  }

  if (hasStudentLoanPlan1) {
    studentLoansPayment = (Math.max(salaryAmount, 22015) - 22015) * 0.09;
  }

  if (hasStudentLoanPostgrad){
    studentLoansPayment += (Math.max(salaryAmount, 21000) - 21000) * 0.06;
  }

  studentLoansPercent = Math.round(studentLoansPayment / salaryAmount * 100);


  takeHome = salaryAmount - taxPaid - NI_Paid - studentLoansPayment;
  

  return {
    personalAllowance,
    taxable,
    taxPaid,
    taxPercent,
    NI_Paid,
    NI_Percent,
    takeHome,
    studentLoansPercent
  };
} 


function Chart() {
  // fetch user input
  const {
    getSalaryAmount,
    getTaxCode,
    getLocation,
    getPartnerIncome,
    getBlindStatus,
    getShowNIStatus,
    getStudentLoanPlan1Status,
    getStudentLoanPlan2Status,
    getStudentLoanPlan4Status,
    getStudentLoanPostgradStatus,
  } = useUserInput();

  const salaryAmount = getSalaryAmount();
  const taxCode = getTaxCode();
  const location = getLocation();
  const partnerIncome = getPartnerIncome();
  const blindStatus = getBlindStatus();
  const showNIStatus = getShowNIStatus();
  const studentLoanPlan1Status = getStudentLoanPlan1Status();
  const studentLoanPlan2Status = getStudentLoanPlan2Status();
  const studentLoanPlan4Status = getStudentLoanPlan4Status();
  const studentLoanPostgradStatus = getStudentLoanPostgradStatus();
  
  // call calculateTax method and fetch tax related variables
  const {
    taxable,
    taxPaid,
    taxPercent,
    NI_Paid,
    NI_Percent,
    takeHome,
    studentLoansPercent,
  } = calculateTax(
    salaryAmount,
    taxCode,
    location,
    partnerIncome,
    blindStatus,
    showNIStatus,
    studentLoanPlan1Status,
    studentLoanPlan2Status,
    studentLoanPlan4Status,
    studentLoanPostgradStatus
  );

  // config for doughnut chart
  const doughnutData = {
    datasets: [{
      data: [
        taxPercent,
        NI_Percent,
        studentLoansPercent,
        100 - taxPercent - NI_Percent
      ],
      backgroundColor: [
        '#B7695F',
        '#7B6EB7',
        '#DACA7D',
        '#5FB787'
      ]
    }],

    labels: [
      "Income Tax",
      "N.I. Tax",
      "Student Loans",
      "Take Home"
    ]
  }

  const doughnutOptions = {
    cutout: '80%',
    elements: {
      arc: {
          borderWidth: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (data) => {
            let label = data.label;
            let value = data.parsed;

            return " " + value + "%";
          }
        },
        titleFont: {
          size: 18,
          family: 'Poppins'
        },
        bodyFont: {
          size: 18,
          family: 'Poppins'
        }
      },
      legend: {
        display: false
      }
    }
  }


  return (
    <div className='chartContainer'>
      <Doughnut
        data={doughnutData}
        options={doughnutOptions}
        className='doughnut'
      />

      <ChartText
        salaryAmount={salaryAmount}
        taxable={taxable}
        taxPaid={taxPaid}
        NI_Paid={NI_Paid}
        takeHome={takeHome}
      />
    </div>
  );
}

export default Chart;
