import React from "react";
import { useTheme } from "../context/themeContext";
import '../styles/styles.css';
import { useUserInput } from "../context/userInputContext";

function ExtraOptions({ showExtraOptions, toggleExtraOptions }) {
	const { darkMode } = useTheme();
	const {
		getPartnerIncome,
		updatePartnerIncome,
		getBlindStatus,
		updateBlindStatus,
		getShowNIStatus,
		updateShowNIStatus,
		getStudentLoanPlan1Status,
		updateStudentLoanPlan1Status,
		getStudentLoanPlan2Status,
		updateStudentLoanPlan2Status,
		getStudentLoanPlan4Status,
		updateStudentLoanPlan4Status,
		getStudentLoanPostgradStatus,
		updateStudentLoanPostgradStatus
	} = useUserInput();

	return (
		<div className={"extraOptionsContainer " + (showExtraOptions ? "show" : "hide") + (darkMode ? " extraOptionsContainerDark" : "")}>
			
			<button onClick={toggleExtraOptions}>
				<img 
					src="/down-arrow4.svg"
					className={"extraOptionsClose " + (darkMode ? "extraOptionsCloseDark" : "")}
					alt="Close"/>
			</button>

			<div>
				<label className={"extraOptionsLabel " + (darkMode ? " extraOptionsLabelDark" : "")}>
					Blind
				</label>
				<input type="checkbox" className="extraOptionsInput" value={getBlindStatus()}
				onChange={(e) => updateBlindStatus(e.target.checked)}></input>
			</div>

			<div>
				<label className={"extraOptionsLabel " + (darkMode ? " extraOptionsLabelDark" : "")}>
					No NI
				</label>
				<input type="checkbox" className="extraOptionsInput" value={!getShowNIStatus()}
				onChange={(e) => updateShowNIStatus(!e.target.checked)}></input>
			</div>

			<div>
				<label className={"extraOptionsLabel " + (darkMode ? " extraOptionsLabelDark" : "")}>
					SL Plan 1 (pre 2012)
				</label>
				<input type="checkbox" className="extraOptionsInput" value={getStudentLoanPlan1Status()}
				onChange={(e) => updateStudentLoanPlan1Status(e.target.checked)}></input>
			</div>

			<div>
				<label className={"extraOptionsLabel " + (darkMode ? " extraOptionsLabelDark" : "")}>
					SL Plan 2 (post 2012)
				</label>
				<input type="checkbox" className="extraOptionsInput" value={getStudentLoanPlan2Status()}
				onChange={(e) => updateStudentLoanPlan2Status(e.target.checked)}></input>
			</div>

			<div>
				<label className={"extraOptionsLabel " + (darkMode ? " extraOptionsLabelDark" : "")}>
					SL Plan 4 (Scotland)
				</label>
				<input type="checkbox" className="extraOptionsInput" value={getStudentLoanPlan4Status()}
				onChange={(e) => updateStudentLoanPlan4Status(e.target.checked)}></input>
			</div>

			<div>
				<label className={"extraOptionsLabel " + (darkMode ? " extraOptionsLabelDark" : "")}>
					SL Postgrad
				</label>
				<input type="checkbox" className="extraOptionsInput" value={getStudentLoanPostgradStatus()}
				onChange={(e) => updateStudentLoanPostgradStatus(e.target.checked)}></input>
			</div>

		</div>
	);
}

export default ExtraOptions;
