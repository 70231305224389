import './styles/styles.css';
import { useContext, useState } from 'react';
import Chart from './components/chart';
import Salary from './components/salary';
import Options from './components/options';
import { useTheme } from './context/themeContext';
import { UserInputProvider } from './context/userInputContext';
import DarkModeSwitch from './components/darkModeSwitch';

function App() {

  //const userInput = 
  const { darkMode } = useTheme();
  window.scrollTo(0,1);
  
  return (
    <div className="App">
      <header id="header">
        <div className='headerText'>
          <img src="/images/icon_white.png" className='headerIcon' alt="header icon"/>
          <button>How much tax?</button><div id='dotcodotuk'>.co.uk</div>
        </div>
        <DarkModeSwitch/>
      </header>

      <UserInputProvider>
        <div className={'page ' + (darkMode ? 'pageDark' : '')}>
          <Chart/>
          <Salary/>
          <Options/>
        </div>
      </UserInputProvider>
      
      <footer id='footer'>
        2023 Rehoboth Ishina Nale
      </footer>
    </div>
  );
}

export default App;
