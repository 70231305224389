import React, { useState } from 'react';
import { useTheme } from '../context/themeContext';

function AdviceCard({ header, isOpen, setIsOpen, children}) {
  const toggleIsOpen = () => setIsOpen(!isOpen);
  const { darkMode } = useTheme();

  return ( 
    <>
      <button
        className={
          "adviceCardHeader "
          + (darkMode ? "adviceCardHeaderDark " : "")
        }
        onClick={toggleIsOpen}
      >
        <span 
          className={"finger " + (isOpen ? "spin " : "")}
        >👉</span>
        {header}
      </button>

      <hr className={
        'adviceCardLine '
        + (darkMode ? 'adviceCardLineDark ': "")
        + (isOpen ? "hide " : "hide ")
      }></hr>

      <div
        className={
          "adviceCardContent "
          + (darkMode ? "adviceCardContentDark " : "")
          + (isOpen ? "show " : "hide ")
        }  
      >
        {children}
      </div>
      
      <hr 
        className={
          'adviceCardLine '
          + (darkMode ? 'adviceCardLineDark ': "")
          + (isOpen ? "hide " : "hide ")
        }
      ></hr>
    </>
  );
}

export default AdviceCard;