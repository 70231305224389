import '../styles//styles.css';
import { useTheme } from '../context/themeContext';
import { useUserInput } from '../context/userInputContext';

function Salary() {

  const { darkMode } = useTheme();
  const { getSalaryAmount, updateSalaryAmount } = useUserInput();

  function handleChange(e) {
    let amnt = parseInt(e.target.value.replace("£", "").replace(",", "")) || 0;
    // cap amount to no more than 6 digits
    if (amnt > 999999) {
      amnt = getSalaryAmount();
    }

    updateSalaryAmount(amnt);
    // format input value: £xxx,xxx
    e.target.value = "£" + amnt.toLocaleString('en-GB');
  }

  return (
    <div className='salaryContainer'>

        <label className={"salaryLabel " + (darkMode ? 'salaryLabelDark' : '')}>
          Salary:
        </label>

        <input type="text" className={"salaryInput " + (darkMode ? 'salaryInputDark' : '')}
         name="salaryInput" onChange={handleChange}></input>
    </div>
  );
}

export default Salary;
