import React from 'react';
import { useTheme } from '../context/themeContext';

function DarkModeSwitch() {

  const { darkMode, toggleDarkMode } = useTheme();

  return ( 
    <button 
      className={"darkModeIcon " + (darkMode ? "darkModeIconDark" : "")} 
      onClick={toggleDarkMode}
    >
      <img 
        alt="switch theme" 
        src="/images/light.svg"
        style={{
          transform: `translateY(${darkMode ? 0 : -200}%)`,
        }}
      />
      <img 
        alt="switch theme" 
        src="/images/dark.svg"
        style={{
          transform: `translateY(${darkMode ? -200 : 0}%)`,
        }}
      />
    </button>
   );
}

export default DarkModeSwitch;