import React, { useContext, useState } from "react";

const UserInputContext = React.createContext();

export function useUserInput() {
	return useContext(UserInputContext);
}

export function UserInputProvider({ children }) { 
    
    const defaultUserInput = {
        "salaryAmount": 0,
        "taxCode": "1257L",
        "location": "GB",
        "partnerIncome": 0,
        "isBlind": false,
        "showNI": true,
        "hasStudentLoanPlan1": false,
        "hasStudentLoanPlan2": false,
        "hasStudentLoanPlan4": false,
        "hasStudentLoanPostgrad": false
    }
    const [userInput, setUserInput] = useState(defaultUserInput);

    const updateUserInput = (updates) => setUserInput(prevState => ({...prevState, ...updates}));

    // -- getters and setters --
    const getSalaryAmount = () => userInput["salaryAmount"];
    const updateSalaryAmount = (newSalaryAmount) => {
        updateUserInput({"salaryAmount": newSalaryAmount})
    }

    const getTaxCode = () => userInput["taxCode"];
    const updateTaxCode = (newTaxCode) => {
        updateUserInput({"taxCode": newTaxCode})
    }

    const getLocation = () => userInput["location"];
    const updateLocation = (newLocation) => {
        updateUserInput({"location": newLocation})
    }

    const getPartnerIncome = () => userInput["partnerIncome"]
    const updatePartnerIncome = (newIncome) => {
        updateUserInput({"partnerIncome": parseInt(newIncome)})
    }

    const getBlindStatus = () => userInput["isBlind"]
    const updateBlindStatus = (newStatus) => {
        updateUserInput({"isBlind": newStatus})
    }

    const getShowNIStatus = () => userInput["showNI"]
    const updateShowNIStatus = (newStatus) => {
        updateUserInput({ "showNI": newStatus })
    }

    const getStudentLoanPlan1Status = () => userInput["hasStudentLoanPlan1"]
    const updateStudentLoanPlan1Status = (newStatus) => {
        updateUserInput({"hasStudentLoanPlan1": newStatus})
    }

    const getStudentLoanPlan2Status = () => userInput["hasStudentLoanPlan2"]
    const updateStudentLoanPlan2Status = (newStatus) => {
        updateUserInput({"hasStudentLoanPlan2": newStatus})
    }

    const getStudentLoanPlan4Status = () => userInput["hasStudentLoanPlan4"]
    const updateStudentLoanPlan4Status = (newStatus) => {
        updateUserInput({"hasStudentLoanPlan4": newStatus})
    }

    const getStudentLoanPostgradStatus = () => userInput["hasStudentLoanPostgrad"]
    const updateStudentLoanPostgradStatus = (newStatus) => {
        updateUserInput({"hasStudentLoanPostgrad": newStatus})
    }

    // for testing purposes only
    const getuserInputStore = () => userInput;

    //--------------------------

    const funcs = {
        getSalaryAmount,
        updateSalaryAmount,
        getTaxCode, 
        updateTaxCode, 
        getLocation,
        updateLocation,
        getPartnerIncome,
        updatePartnerIncome,
        getBlindStatus,
        updateBlindStatus,
        getShowNIStatus,
        updateShowNIStatus,
        getStudentLoanPlan1Status,
        updateStudentLoanPlan1Status,
        getStudentLoanPlan2Status,
        updateStudentLoanPlan2Status,
        getStudentLoanPlan4Status,
        updateStudentLoanPlan4Status,
        getStudentLoanPostgradStatus,
        updateStudentLoanPostgradStatus,
        getuserInputStore,
    };

    return (
        <UserInputContext.Provider value={funcs}>
            {children}
        </UserInputContext.Provider>
    )
}
