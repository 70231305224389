import React, { useContext, useState } from "react";

const ThemeContext = React.createContext();

export function useTheme() {
	return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
	const [darkMode, setDarkMode] = useState(false);

	const toggleDarkMode = () => setDarkMode(!darkMode);
	
	const funcs = {
		darkMode, 
		setDarkMode, 
		toggleDarkMode
	}

	return (
		<ThemeContext.Provider value={funcs}>
			{children}
		</ThemeContext.Provider>
	);
}
