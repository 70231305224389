const adviceCardsData = [
  {
    "headerText": "Income Tax",
    "content":
    <>
      <h3>Summary</h3>
      
      <p>
        Income tax is a compulsory contribution you make to the goverment upon recieving employment income. 
        In the United Kingdom (incl. Scotland), income tax is calculated based on a <b>progressive tax system</b>.
        This means tax rates progress as taxable income increases, based on <b>set
        income brackets</b>.
      </p>
      <br></br>  
      <h3>Tax Brackets</h3>
      
      <p>
        <u><b>Non-Scottish residents</b> in the UK:</u>
        <br></br> 
        <small><i>*assuming personal allowance of £12,570 (most common)</i></small>
      </p>
      <table className="salaryBracketsTable">
        <tr>
          <th>Band</th>
          <th>From</th>
          <th>To</th>
          <th>% Tax</th>
        </tr>
        <tr>
          <td>Personal Allowance</td>
          <td>£0</td>
          <td>£12,570</td>
          <td>0%</td>
        </tr>
        <tr>
          <td>Basic rate</td>
          <td>£12,570</td>
          <td>£50,270</td>
          <td>20%</td>
        </tr>
        <tr>
          <td>Higher rate</td>
          <td>£50,270</td>
          <td>£125,140</td>
          <td>40%</td>
        </tr>
        <tr>
          <td>Additional rate</td>
          <td>£125,140</td>
          <td>-</td>
          <td>45%</td>
        </tr>
      </table>
      <br></br>

      <p>
        <u><b>Non-Scottish residents</b> in the UK:</u>
        <br></br> 
        <small><i>*variable personal allowance (PA = Personal Allowance)</i></small>
      </p>
      <table className="salaryBracketsTable">
        <tr>
          <th>Band</th>
          <th>From</th>
          <th>To</th>
          <th>% Tax</th>
        </tr>
        <tr>
          <td>Personal Allowance</td>
          <td>£0</td>
          <td>PA</td>
          <td>0%</td>
        </tr>
        <tr>
          <td>Basic rate</td>
          <td>PA</td>
          <td>PA + £37,700</td>
          <td>20%</td>
        </tr>
        <tr>
          <td>Higher rate</td>
          <td>PA + £37,700</td>
          <td>£125,140</td>
          <td>40%</td>
        </tr>
        <tr>
          <td>Additional rate</td>
          <td>£125,140</td>
          <td>-</td>
          <td>45%</td>
        </tr>
      </table>
      <br></br>  
      <p>
        <u><b>Scottish residents</b> in the UK:</u>
        <br></br> 
        <small><i>*assuming personal allowance of £12,570 (most common)</i></small>
      </p>
        
      <table className="salaryBracketsTable">
        <tr>
          <th>Band</th>
          <th>From</th>
          <th>To</th>
          <th>% Tax</th>
        </tr>
        <tr>
          <td>Personal Allowance</td>
          <td>£0</td>
          <td>£12,570</td>
          <td>0%</td>
        </tr>
        <tr>
          <td>Starter rate</td>
          <td>£12,570</td>
          <td>£14,732</td>
          <td>19%</td>
        </tr>
        <tr>
          <td>Basic rate</td>
          <td>£14,732</td>
          <td>25,688</td>
          <td>20%</td>
        </tr>
        <tr>
          <td>Intermediate rate</td>
          <td>25,688</td>
          <td>£43,662</td>
          <td>21%</td>
        </tr>
        <tr>
          <td>Higher rate</td>
          <td>£43,662</td>
          <td>£125,140</td>
          <td>42%</td>
        </tr>
        <tr>
          <td>Additional rate</td>
          <td>£125,140</td>
          <td>-</td>
          <td>47%</td>
        </tr>
      </table>
      <br></br>
        
      <p>
        <u><b>Scottish residents</b> in the UK:</u>
        <br></br> 
        <small><i>*variable personal allowance (PA = Personal Allowance)</i></small>
      </p>
        
      <table className="salaryBracketsTable">
        <tr>
          <th>Band</th>
          <th>From</th>
          <th>To</th>
          <th>% Tax</th>
        </tr>
        <tr>
          <td>Personal Allowance</td>
          <td>£0</td>
          <td>PA</td>
          <td>0%</td>
        </tr>
        <tr>
          <td>Starter rate</td>
          <td>PA</td>
          <td>PA + £2,162</td>
          <td>19%</td>
        </tr>
        <tr>
          <td>Basic rate</td>
          <td>PA + £2,162</td>
          <td>PA + £13,118</td>
          <td>20%</td>
        </tr>
        <tr>
          <td>Intermediate rate</td>
          <td>PA + £13,118</td>
          <td>PA + £31,092</td>
          <td>21%</td>
        </tr>
        <tr>
          <td>Higher rate</td>
          <td>PA + £31,092</td>
          <td>£125,140</td>
          <td>42%</td>
        </tr>
        <tr>
          <td>Additional rate</td>
          <td>£125,140</td>
          <td>-</td>
          <td>47%</td>
        </tr>
      </table>
      <br></br>
      
    </>
  },
  {
    "headerText": "Tax Codes / Personal Allowance",
    "content":
    <>
      <h3>Summary</h3>
      <p>
        Personal allowance is the amount of income you <b>do not get taxed on</b>.
        The amount of personal allowance you recieve is <b>dependant on your tax code</b>.
      </p>
      <p>
        Your tax code is built up of numbers, and then letters. The numbers represent the amount,
        and the letters represent your situation and how that affects your personal allowance.
        Usually, to calculate your personal allowance just multiply the tax code number part by 10. e.g. 1257L = £12,570.&nbsp;
        <a href='https://www.gov.uk/employee-tax-codes/letters'>Click here</a> to find out more about how the letters part of your tax code affect your personal allowance.
      </p>

    </>
  },
  {
    "headerText": "NI Tax",
    "content":
    <>
      <b>Hello world!</b> This is some text
      <p>This is in it's own paragraph!</p>
    </>
  },
  {
    "headerText": "Student Loans",
    "content":
    <>
      <b>Hello world!</b> This is some text
      <p>This is in it's own paragraph!</p>
    </>
  },
  {
    "headerText": "Find out more",
    "content":
    <>
      <b>Hello world!</b> This is some text
      <p>This is in it's own paragraph!</p>
    </>
  },
]

export default adviceCardsData