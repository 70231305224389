import React, { useEffect, useState } from 'react';
import AdviceCard from './adviceCard';
import { useTheme } from '../context/themeContext';
import adviceCardsData from './adviceCardsData'

function AdviceMenu({ showAdviceMenu, toggleAdviceMenu }) {  
  const { darkMode } = useTheme();
  const [ openCard, setOpenCard ] = useState(-1);
  
  const adviceCards = adviceCardsData.map((data, idx) => {
    return (
      <AdviceCard
        header={data.headerText}
        isOpen={openCard === idx}
        setIsOpen={() => setOpenCard(openCard === idx ? -1 : idx)} // if current is open then set to -1
      >
        {data.content}
      </AdviceCard>)
  })

  return (
    <>
      <div className={'blurBackdrop ' + (showAdviceMenu ? "show " : "hide ")}></div>
      <div className={
        "adviceMenuContainer " 
        + (showAdviceMenu ? "show " : "hide ")
        + (darkMode ? "adviceMenuContainerDark": "")}
      >
        <button 
          onClick={toggleAdviceMenu}
          className="adviceMenuContainerCloseBtn"
        >
          <img src="images/cross.png" alt='×'/> Close
        </button>
          
          <div className='scrollable'>
            {adviceCards}
          </div>
      </div>
    </>
  );
}

export default AdviceMenu;