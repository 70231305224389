import React, { useState } from 'react';
import { useTheme } from '../context/themeContext';
import FlagContainer from './flagsContainer';
import { useUserInput } from '../context/userInputContext'
import ExtraOptions from './extraOptions';
import AdviceMenu from './adviceMenu';

function Options() {

  const { darkMode } = useTheme();
  const { updateTaxCode } = useUserInput();

  const [ showExtraOptions, setShowExtraOptions ] = useState(false);
  const toggleExtraOptions = () => setShowExtraOptions(!showExtraOptions);

  const [ showAdviceMenu, setShowAdviceMenu ] = useState(false);
  const toggleAdviceMenu = () => setShowAdviceMenu(!showAdviceMenu);

  const handleLostFocus = (e) => {
    // default to 1257L if blank
    updateTaxCode((e.target.value.trim() === '') ? "1257L" : e.target.value);
  }

  return (
    <div className='optionsContainer'>

      <FlagContainer/>

      <div className="taxCodeContainer">
        <label className={"taxCodeLabel " + (darkMode && 'taxCodeLabelDark')}>
          Tax Code:
        </label>

        <input type="text" className={"taxCodeInput " + (darkMode && 'taxCodeInputDark')} 
          name="taxCodeInput" placeholder='1257L' onBlur={handleLostFocus}></input>
        
        <div className={"bottomLinksContainer " + (darkMode && 'bottomLinksContainerDark')}>
          <button onClick={toggleExtraOptions} className="bottomLinks">
            More options
          </button>
          {"  /  "}
          <a href="https://www.gov.uk/tax-help" className="bottomLinks"
          target="_blank" rel="noreferrer noopener">
            Tax advice
          </a>
        </div>
      </div>

      <div className={"questionMark " + (darkMode && "questionMarkDark")}>
        {""}
      </div>


      <ExtraOptions 
        showExtraOptions={showExtraOptions} 
        toggleExtraOptions={toggleExtraOptions}
      />

      <AdviceMenu 
        showAdviceMenu={showAdviceMenu} 
        toggleAdviceMenu={toggleAdviceMenu}
      />

    </div>
  );
}

export default Options;
