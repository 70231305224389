import { useState } from 'react';
import '../styles/styles.css';
import { useUserInput } from '../context/userInputContext';
import { useTheme } from '../context/themeContext';

function FlagContainer() {

  const { getLocation, updateLocation } = useUserInput();
  const { darkMode } = useTheme();

  return (
    <div className='flagsContainer'>
      <div className={'locationLabel ' + (darkMode ? "locationLabelDark" : "")}>Location:</div>
      <button>
        <img src="/images/gb.png" alt="Great Britain" className="flags"
        style={{
          border: (getLocation()==="GB") ? "5px solid #5FB787" : "none"
        }}
        onClick={() => updateLocation("GB")}/>
      </button>

      <button>
        <img src="/images/sct.png" alt="Scotland" className="flags"
        style={{
          border: (getLocation()==="SCT") ? "5px solid #5FB787" : "none"
        }}
        onClick={() => updateLocation("SCT")}/> 
      </button>
    </div>
  ); 
}

export default FlagContainer;
