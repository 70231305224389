import React from "react";
import '../styles/styles.css';
import { useTheme } from "../context/themeContext";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import 'swiper/css';

// react mini components - buttons to go to next / prev slides
function SwiperNextSlide(){
	
	const swiper = useSwiper();
	const gotoNextSlide = () => swiper.slideNext();

	return(
		<button onClick={gotoNextSlide}>→</button>
	);
}

function SwiperPrevSlide(){
	
	const swiper = useSwiper();
	const gotoPrevSlide = () => swiper.slidePrev();

	return(
		<button onClick={gotoPrevSlide}>←</button>
	);
}


function ChartText({ salaryAmount, taxable, taxPaid, NI_Paid, takeHome }) {
	const { darkMode } = useTheme();

	// set chartText to show small message asking for user input if user input is 0 / NaN,
	// otherwise show text

	const yearlyBody = (
		<SwiperSlide className={'chartText ' + (darkMode ? 'chartTextDark' : '')}>
			<div className={"chartTextPeriod " + (darkMode ? 'chartTextPeriodDark' : '')}>
				{'  YEARLY '}<SwiperNextSlide/>
			</div>
			<br></br>
			{"Gross: £" + salaryAmount.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"Taxable: £" + taxable.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"Inc. Tax: £" + taxPaid.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"N.I. Tax: £" + NI_Paid.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			<div className={"takeHomeText " + (darkMode ? 'takeHomeTextDark' : '')}>
				{"Net: £" + takeHome.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			</div>
		</SwiperSlide>
	)

	const monthlyBody = (
		<SwiperSlide className={'chartText ' + (darkMode ? 'chartTextDark' : '')}>
			<div className={"chartTextPeriod " + (darkMode ? 'chartTextPeriodDark' : '')}>
				<SwiperPrevSlide/>{' MONTHLY '}<SwiperNextSlide/>
			</div>
			<br></br>
			{"Gross: £" + (salaryAmount / 12).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"Taxable: £" + (taxable / 12).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"Inc. Tax: £" + (taxPaid / 12).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"N.I. Tax: £" + (NI_Paid / 12).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			<div className={"takeHomeText " + (darkMode ? 'takeHomeTextDark' : '')}>
				{"Net: £" + (takeHome / 12).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			</div>
		</SwiperSlide>
	)

	const weeklyBody = (
		<SwiperSlide className={'chartText ' + (darkMode ? 'chartTextDark' : '')}>
			<div className={"chartTextPeriod " + (darkMode ? 'chartTextPeriodDark' : '')}>
				<SwiperPrevSlide/>{' WEEKLY  '}
			</div>
			<br></br>
			{"Gross: £" + (salaryAmount / 51).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"Taxable: £" + (taxable / 51).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"Inc. Tax: £" + (taxPaid / 51).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			{"N.I. Tax: £" + (NI_Paid / 51).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			<br></br>
			<div className={"takeHomeText " + (darkMode ? 'takeHomeTextDark' : '')}>
				{"Net: £" + (takeHome / 51).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
			</div>
		</SwiperSlide>
	)
	
	// show chartText if salary given, else show message to user
	const chartTextBody = (salaryAmount > 0) ? (
		<Swiper className='chartTextBody' direction= 'horizontal' spaceBetween='200'>
			{yearlyBody}
			{monthlyBody}
			{weeklyBody}
		</Swiper>
	):
	(
		<div className={'chartTextBody chartText ' + (darkMode ? 'chartTextDark' : '')}>
			Enter a salary and / or tax code below 👇
		</div>
	);

	
	return (
		<>
			{chartTextBody}
		</>
	);
}

export default ChartText;